<script setup lang="ts">
import TheHeader from '~/components/TheHeader.vue'

if (process.client) document.body.style.backgroundColor = '#222437'
</script>

<template>
  <TheHeader />
  <NuxtPage />
</template>
